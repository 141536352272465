<template>
  <vue-good-table
    :columns="columns"
    :rows="rows"
    :sort-options="{ enabled: true }"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: pageLength,
    }"
  >
    <div slot="emptystate" class="text-center">
      {{ $t('Global.Emptystate') }}
    </div>

    <template slot="table-column" slot-scope="props">
      <span v-if="props.column.label !== null">
        <span v-b-tooltip.hover.top="props.column.label">
          {{ props.column.label }}
        </span>
      </span>
    </template>

    <template slot="table-row" slot-scope="props">
      <span
        v-if="props.column.field.includes($t('Evaluations.InternalShifts.Is'))"
      >
        <span
          :style="{
            color:
              props.row[props.column.field] <
              props.row[
                $t('Evaluations.InternalShifts.Should') +
                  props.column.field.slice(3)
              ]
                ? 'red'
                : '',
          }"
        >
          {{ props.row[props.column.field] }}
        </span>
      </span>
      <span
        v-else-if="
          props.column.field.includes($t('Evaluations.InternalShifts.Should'))
        "
      >
        {{ props.row[props.column.field] }}
      </span>
      <span
        v-else-if="
          props.column.field === $t('Evaluations.InternalShifts.ContractType')
        "
        style="white-space: pre"
      >
        {{
          $t(
            `Management.Employees.Contract.Types.${
              props.row[props.column.field]
            }`,
          )
        }}
      </span>
      <span
        v-else-if="
          props.column.field === $t('Evaluations.InternalShifts.IsSickSum')
        "
        style="white-space: pre"
      >
        <span
          v-b-tooltip.hover.top="
            formatSickContext(props.row[props.column.field])
          "
        >
          {{ props.row[props.column.field].length }}
        </span>
      </span>
      <span
        v-else-if="
          props.column.field === $t('Evaluations.InternalShifts.Employee')
        "
        style="white-space: pre"
      >
        {{ props.row[props.column.field] }}
      </span>
    </template>
    <template slot="pagination-bottom" slot-scope="props">
      <div class="d-flex justify-content-between flex-wrap px-2 pb-2">
        <div class="d-flex align-items-center mb-0 mt-1 flex-wrap">
          <span class="text-nowrap">
            {{ $t('Global.ShowingOneOf') }}
          </span>
          <v-select
            v-model="pageLength"
            class="mx-1"
            style="min-width: 75px"
            :clearable="false"
            :options="['10', '15', '20']"
            @input="value => props.perPageChanged({ currentPerPage: value })"
          />
          <span class="text-nowrap">{{ $t('Global.EntriesShown') }}</span>
        </div>
        <div>
          <b-pagination
            :value="1"
            :total-rows="props.total"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="value => props.pageChanged({ currentPage: value })"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
  </vue-good-table>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
import moment from 'moment'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'

export default {
  components: {
    VueGoodTable,
    vSelect,
    BPagination,
  },

  props: {
    employeesEvaluations: {
      type: Array,
      default: () => [],
    },
    searchTerm: {
      type: String,
      default: () => '',
    },
  },

  data() {
    return {
      pageLength: 10,
      rows: [],
      state: {
        Is: this.$t('Evaluations.InternalShifts.Is'),
        Should: this.$t('Evaluations.InternalShifts.Should'),
        IsSickSum: this.$t('Evaluations.InternalShifts.IsSickSum'),
        IsSum: this.$t('Evaluations.InternalShifts.IsSum'),
        ShouldSum: this.$t('Evaluations.InternalShifts.ShouldSum'),
      },
      tableTitle: {
        EmployeeNumber: this.$t('Evaluations.InternalShifts.EmployeeNumber'),
        Employee: this.$t('Evaluations.InternalShifts.Employee'),
        ContractType: this.$t('Evaluations.InternalShifts.ContractType'),
      },
    }
  },

  computed: {
    columns() {
      if (!this.employeesEvaluations || !this.rows.length) {
        return []
      }

      const columns = []
      Object.keys(this.rows[0]).forEach(key => {
        if (
          key === this.tableTitle.Employee ||
          key === this.tableTitle.ContractType
        ) {
          columns.push({
            label: key,
            field: key,
            tdClass: 'text-left',
          })
          return
        }

        if (key === this.tableTitle.EmployeeNumber) {
          columns.push({
            label: key,
            field: key,
            type: 'number',
            thClass: 'text-left',
            tdClass: 'text-left',
          })
          return
        }

        columns.push({
          label: key,
          field: key,
          type: 'number',
          thClass: 'custom-th-class',
          tdClass: 'text-center',
          width: '65px',
          tooltip: key,
        })
      })
      return columns
    },
  },

  watch: {
    employeesEvaluations() {
      this.rows = []

      if (!this.employeesEvaluations) return

      this.employeesEvaluations.forEach(elem => {
        const data = {}

        data[this.tableTitle.EmployeeNumber] = elem.employee.employeeNumber
        data[this.tableTitle.Employee] =
          `${elem.employee.firstname} ${elem.employee.lastname}`
        data[this.tableTitle.ContractType] = elem.employee.contract

        elem.layers.forEach(layer => {
          layer.shifts.forEach(shift => {
            const valueShouldString = `${`${this.state.Should}\n`}${
              layer.layerName
            }\n${shift.shiftName}`

            const valueIsString = `${`${this.state.Is}\n`}${layer.layerName}\n${
              shift.shiftName
            }`

            data[valueShouldString] = shift.values.should
            data[valueIsString] = shift.values.is
          })
        })

        data[this.state.IsSickSum] = elem.sickContext
        data[this.state.ShouldSum] = elem.totalShould
        data[this.state.IsSum] = elem.totalIs

        this.rows.push(data)
      })
    },
  },

  methods: {
    formatSickContext(sickContext) {
      if (sickContext.length === 0) return undefined

      return sickContext
        .map(x => `${x.shiftName} ${moment(x.date).format('DD.MM.YYYY')}`)
        .join(', ')
    },
  },
}
</script>
<style lang="scss">
.custom-th-class {
  text-align: center;
  font-size: 0.6em;
  white-space: pre;
  overflow: hidden;
  margin-top: 10px;
}

.text-left {
  text-align: left;
  font-size: 0.9em;
}

.text-center {
  text-align: center;
}
</style>
