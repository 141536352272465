<template>
  <section>
    <b-card>
      <h3 class="mb-1 font-weight-bolder">
        {{ $t('Global.Filters') }}
      </h3>
      <b-row align-h="between">
        <b-col lg="3" md="4" sm="12">
          <label>
            {{ $t('Evaluations.InternalShifts.DateSpan') }}
          </label>
          <b-form-group>
            <flat-pickr
              v-model="rangeDate"
              class="form-control"
              :config="{
                mode: 'range',
                maxDate: currentMaxDate,
                defaultDate: [startDate, endDate],
                dateFormat: 'd.m.Y',
                onChange: handleChange,
              }"
            />
          </b-form-group>
        </b-col>
        <b-col lg="3" md="4" sm="12">
          <label>
            {{ $t('Evaluations.InternalShifts.ContractType') }}
          </label>
          <v-select
            v-model="filter.contract"
            :placeholder="$t('Global.Filter')"
            :options="EmployeeContractsOptions"
          />
        </b-col>
        <b-col lg="3" md="4" sm="12">
          <label>
            {{ $t('Evaluations.InternalShifts.Area') }}
          </label>
          <v-select
            v-model="filter.area"
            :placeholder="$t('Global.Filter')"
            :options="layers"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <div class="d-flex custom-search row mb-2 px-2 pt-2 align-items-center">
        <div class="col-md-6">
          <h2 class="m-0 font-weight-bolder">
            {{ $t('Global.All') }} {{ $t('Management.Employees.Employees') }}
          </h2>
        </div>
        <div class="col-md-6 d-md-flex justify-content-end">
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Global.Search')"
              type="text"
              class="d-inline-block search"
            />
          </div>
          <feather-icon
            class="download-icon"
            icon="DownloadIcon"
            @click="download"
          />
        </div>
      </div>
      <Table :employees-evaluations="rowData" :search-term="searchTerm" />
    </b-card>
  </section>
</template>

<script>
import moment from 'moment'
import { BFormInput, BCard, BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import contracts from '../../management/employees/utility/contracts'
import Table from './components/table.vue'
import { initialLoadingForInternalShifts, load } from '../utility/load'

export default {
  components: {
    BFormInput,
    BCard,
    Table,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    flatPickr,
  },

  data: () => ({
    filter: {
      contract: null,
      area: null,
    },
    searchTerm: '',
    loading: false,
    rangeDate: null,
    startDate: null,
    endDate: null,
    layers: [],
    employeesEvaluations: null,
  }),

  computed: {
    currentMaxDate() {
      return new Date(Date.now() - 24 * 60 * 60 * 1000)
    },
    lastMonthStart() {
      return moment().subtract(1, 'month').startOf('month')
    },
    lastMonthEnd() {
      return moment().subtract(1, 'month').endOf('month')
    },
    EmployeeContractsOptions() {
      const options = []
      Object.keys(contracts).forEach(key => {
        options.push({
          label: this.$t(`Management.Employees.Contract.Types.${key}`),
          key,
        })
      })
      return options
    },
    rowData() {
      if (
        (this.filter.area === null || this.filter.contract === null) &&
        !this.employeesEvaluations
      ) {
        return this.employeesEvaluations
      }

      const copyOfEmployeesEvaluations = JSON.parse(
        JSON.stringify(this.employeesEvaluations),
      )

      const filteredData = copyOfEmployeesEvaluations.filter(x => {
        if (this.filter.contract === null) return true
        return x.employee.contract === this.filter.contract.key
      })

      if (this.filter.area === null) return filteredData

      filteredData.forEach(item => {
        item.layers = item.layers.filter(x => x.layerName === this.filter.area)
      })
      return filteredData
    },
  },

  watch: {
    rangeDate() {
      const formattedStartDate = moment(this.startDate).format('YYYY-MM-DD')
      const formattedEndDate = moment(this.endDate).format('YYYY-MM-DD')
      this.loadNewDate(formattedStartDate, formattedEndDate)
    },
  },

  async created() {
    this.startDate = this.lastMonthStart.toDate()
    this.endDate = this.lastMonthEnd.toDate()
    const formattedStartDate = moment(this.startDate).format('YYYY-MM-DD')
    const formattedEndDate = moment(this.endDate).format('YYYY-MM-DD')
    await this.initialLoadingForInternalShifts(
      formattedStartDate,
      formattedEndDate,
    )
    if (this.employeesEvaluations) {
      this.employeesEvaluations[0].layers.forEach(layer => {
        this.layers.push(layer.layerName)
      })
    }
  },

  methods: {
    handleChange(selectedDates) {
      const [startDate, endDate] = selectedDates
      this.startDate = startDate
      this.endDate = endDate || startDate
    },

    async loadNewDate(startDateString, endDateString) {
      await this.initialLoadingForInternalShifts(startDateString, endDateString)
    },

    async initialLoadingForInternalShifts(...args) {
      return initialLoadingForInternalShifts.call(this, ...args)
    },

    async load(...args) {
      return load.call(this, ...args)
    },

    async download() {
      try {
        const response = await this.$axios.post(
          `evaluations/internal-shifts/download/${moment(this.startDate).format(
            'YYYY-MM-DD',
          )}/${moment(this.endDate).format('YYYY-MM-DD')}`,
          this.rowData,
          { responseType: 'blob' },
        )
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url

        let fileEnd = `${moment(this.startDate).format('YYYY-MM-DD')}.zip`

        if (this.startDate !== this.endDate) {
          fileEnd = `${moment(this.startDate).format('YYYY-MM-DD')}_${moment(
            this.endDate,
          ).format('YYYY-MM-DD')}.zip`
        }

        const linkText = `${this.$t('Evaluations.Evaluations')}_${this.$t(
          'Evaluations.InternalShifts.Internal',
        )}_${this.$t('Evaluations.Employee')}${fileEnd}`

        link.setAttribute('download', linkText)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        this.$alert.error()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.download-icon {
  margin-left: 25px;
  width: 30px;
  height: 30px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
