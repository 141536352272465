export default {
  miniJob: 'miniJob',
  shortTermEmployment: 'shortTermEmployment',
  slidingZoneTariff: 'slidingZoneTariff',
  workingStudents: 'workingStudents',
}

const TimeUnits = {
  month: 'Month',
  year: 'Year',
  week: 'Week',
}

export const ContactLayersToWork = {
  miniJob: {
    per: TimeUnits.month,
    value: 4,
  },
  shortTermEmployment: {
    per: TimeUnits.year,
    value: 70,
  },
  slidingZoneTariff: {
    per: TimeUnits.month,
    value: 5,
  },
  workingStudents: {
    per: TimeUnits.week,
    value: 2,
  },
}
